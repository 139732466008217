<template>
  <div class="container-lg h-100">
    <div class="container-lg h-100" id="img">
      <div style="max-width: 600px">
        <h1 class="pt-5">Hmm...</h1>
        <div>
          <div class="lead">We couldn't find that page.</div>
          <div>
            Mr. Kelly recommends checking the URL for any typos. If you still
            can't find what you're looking for, try the gear bin by the windows
            (it's where all the lost dojo items go).
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#img {
  background: url("./../assets/404.png") no-repeat right bottom;
  background-size: auto 75%;
}
</style>