import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import NotFound from "@/views/NotFound.vue"

import Password from "@/views/Password.vue"
import Landing from "@/views/Landing.vue"
import Page from "@/views/Page.vue"
import GroupPage from "@/views/GroupPage.vue"
import TopicPage from "@/views/TopicPage.vue"
import VideoPage from "@/views/VideoPage.vue"

import hasSession from '@/scripts/hasSession.ts'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { noSession: true }
  },
  {
    path: '/password',
    name: "Password",
    component: Password,
    meta: { noSession: true }
  },
  {
    path: "/landing",
    name: "Landing",
    component: Landing
  },
  {
    path: "/page",
    name: "Page",
    component: Page,
    children: [
      {
        path: ':groupID',
        component: GroupPage,
        name : "Group page"
      },
      {
        path: ':groupID/:topicID',
        component: TopicPage,
        name : "Topic page"
      },
      {
        path: ':groupID/:topicID/:videoID',
        component: VideoPage,
        name : "Video page"
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// eslint-disable-next-line no-unused-vars
router.beforeEach(async function (to, from, next) { //Redirect before any API calls
  console.log(`router.beforeEach (to ${to.fullPath})`);
  if (!await hasSession() && to.meta.noSession != true) { //Requires a session and doesn't have one
    console.log("Redirecting to /password (router.beforeEach)");
    var redirectURL = to.fullPath;
    console.log(`Redirect URL: ${redirectURL}`);
    if (redirectURL == "/landing") {
      next("/password"); //Defaults to redirect to /landing, if clicking get started on home
    } else {
      next(`/password?redirect=${encodeURIComponent(to.fullPath)}`);
    }
  } else {
    console.log("Finished router.beforeEach");
    next();
  }
});


export default router