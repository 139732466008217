<template>
  <div class="col-12 col-md-6 col-lg-6 my-2 mb-3">
    <div class="card h-100 px-0 mx-2 border-0 shadow-lg rounded-3">
      <div class="ratio ratio-21x9">
        <img
          :src="'https://group-images.kovarslaguna.com/' + groupID + '.jpg'"
          class="card-img-top card-img-cover"
        />
      </div>
      <div class="card-body px-3 d-flex flex-column text-center">
        <h4 v-text="name"></h4>
        <p v-text="description" id="description" class="fw-light"></p>
        <router-link
          class="btn btn-primary d-block w-100 mx-auto mt-auto"
          style="max-width: 200px"
          :to="'/page/' + groupID"
          >Launch
          <font-awesome-icon
            :icon="['fas', 'external-link-alt']"
            class="ps-1"
          ></font-awesome-icon
        ></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    description: String,
    groupID: String,
  },
};
</script>

<style scoped>
.card-img-cover {
  object-fit: cover;
}

#description {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>