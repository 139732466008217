<template>
  <div
    id="container"
    class="
      w-100
      h-100
      d-flex
      justify-content-center
      align-items-center
      text-white
    "
  >
    <div id="password" class="text-center">
      <div class="pb-2">
        <h3 class="display-5 fw-bold">Let's rock this!</h3>
        <p class="lead">
          You’re one step away from some fantabulous awesomeness - we just need
          the site password!
        </p>
      </div>
      <div id="inputContainer" class="d-flex mx-auto">
        <input
          type="password"
          class="form-control"
          placeholder="Password"
          @keyup.enter="submit"
          v-model="password"
          autofocus
          maxlength="100"
        />
        <button
          id="submit"
          class="btn btn-primary ms-2 px-4"
          @click="submit"
          :disabled="submitLoad"
        >
          Submit
        </button>
      </div>
      <transition name="fade" mode="out-in">
        <div class="pt-2 d-flex align-items-center justify-content-center" :key="errorCount" v-visible="errorMessage != ''">
          <font-awesome-icon :icon="['fas', 'exclamation-circle']" class="pe-2 lead" style="font-size: 30px;"></font-awesome-icon>
          <span
            class="lead"
            v-text="errorMessage != '' ? errorMessage : '&nbsp;'"
          ></span>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>

import addSession from '@/scripts/addSession.ts'

export default {
  data() {
    return {
      password : "",
      errorMessage: "",
      errorCount : 0,
      submitLoad: false,
    };
  },
  methods: {
    showError (message) { //Use fade transition, even if the message is the same value
      this.errorMessage = message;
      this.errorCount++;
    },
    async submit() {
      if (this.password == "") {
        this.showError("Please enter a password");
        return;
      }

      this.submitLoad = true;

      var vm = this;

      addSession(this.password).then(function () {
        var redirectURL = vm.$route.query.redirect;
        vm.$router.push(redirectURL ? decodeURIComponent(redirectURL) : "/landing");
      }).catch(function (err) {
        vm.submitLoad = false;

        vm.showError(err == "Incorrect password" ? "Sorry, please try again!" : err);
        return;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#container {
  background-image: url("./../assets/password-background.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

#password {
  max-width: 600px;
  width: 90%;
}

#inputContainer {
  width: 100%;
  max-width: 400px;
}

.fade-enter-active { //No .fade-leave-active
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>