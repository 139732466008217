<template>
  <div class="h-100 bg-light">
    <router-view class="h-100" />
    <transition name="err-alert">
      <div
        class="alert alert-warning alert-dismissible position-absolute overflow-auto"
        v-show="errAlert"
        id="err-alert"
        role="alert"
      >
        Sorry, something went wrong! <button class="btn btn-link py-0" @click="showErr = !showErr">Error info</button><br>
        {{ showErr ? errMessage : "" }}
      <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="errAlert = false"
        ></button>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
//Import scss
@import "./scss/bootstrap.scss";
</style>

<style>
html,
body {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

body {
  font-family: "Roboto", sans-serif !important;
}

#app {
  height: 100%;
  overflow: auto; /* Doesn't allow scroll from offcanvas Home page without overflow auto :) */
}

#err-alert {
  bottom: 20px;
  left: 20px;
  max-width: 50%;
  max-height: 30%; /* TODO - change units? */
  word-break: break-all; /* Change? More options too */
}

.err-alert-enter-active {
  transition: opacity 0.5s ease;
}

.err-alert-enter-from,
.err-alert-leave-to {
  opacity: 0;
}
</style>

<script>
import { app } from "@/main.js"

export default {
  data() {
    return {
      errAlert: false,
      errMessage : "",
      showErr : false
    };
  },
  mounted() {
    var vm = this;
    app.config.errorHandler = function (err) {
      //Including axios errors :) catches await axios
      console.log("Error handler!"); //TODO - add error message popup
      console.log(err);
      vm.errAlert = true;
      vm.errMessage = err;
    };
  },
};
</script>