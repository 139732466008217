<template>
  <div>
    <navbar />
    <div class="container pt-4 mx-auto" style="max-width: 900px">
      <div class="text-center mb-4">
        <h1>Welcome to the Laguna Video Library!</h1>
        <p>
          We’re so excited to have you here. Choose your group below and let’s
          get started!!!
        </p>
      </div>
      <div class="mt-3">
        <loading v-if="loading" />
        <span v-if="!loading && groups.length == 0">No groups</span>
        <div class="mx-auto">
          <div class="row justify-content-center">
            <groupCard
              v-for="group in groups"
              :key="group.groupID"
              :name="group.name"
              :description="group.description"
              :groupID="group.groupID"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import groupCard from "@/components/group-card.vue";
import navbar from "@/components/navbar.vue";
import loading from '@/components/loading.vue'

export default {
  data() {
    return {
      groups: [],
      loading: true,
    };
  },
  components: {
    groupCard,
    navbar,
    loading
  },
  async mounted() {
    this.groups = (await this.$axios.get("/groups")).data.Items;
    this.loading = false;
  }
};
</script>


<style lang="scss" scoped>
/* */
</style>