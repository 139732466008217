<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light py-3" style="background-color: hsl(210, 0%, 92%)">
      <div class="container mx-auto" style="max-width: 900px">
        <router-link id="title" class="navbar-brand fw-bold" to="/landing"
          >Laguna Video Library</router-link
        >
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvas"
          aria-controls="offcanvas"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="d-flex flex-grow-1 align-items-center d-none d-lg-block"
          style="flex-basis: auto"
        >
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link
                :class="
                  'nav-link' + ($route.fullPath == '/landing' ? ' active' : '')
                "
                aria-current="page"
                to="/landing"
                >Home</router-link
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://docs.google.com/forms/d/e/1FAIpQLSdxWKJD7t_uqO5XIrRrgy_LYu08xzZcdTqYdVf4Gp3aHDLyKw/viewform?usp=sf_link" target="_blank"
                >Send feedback</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvas">
      <div class="offcanvas-header">
        <h5
          class="offcanvas-title"
          v-text="offcanvasTitle ? offcanvasTitle : 'Kovar\'s Laguna'"
        ></h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body px-5">
        <router-link
          class="d-block text-dark fw-bold text-decoration-none"
          to="/landing"
          >Home</router-link
        >
        <a
          class="d-block text-dark fw-bold text-decoration-none pt-2"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdxWKJD7t_uqO5XIrRrgy_LYu08xzZcdTqYdVf4Gp3aHDLyKw/viewform?usp=sf_link"
          target="_blank"
          >Send feedback</a
        >
        <hr v-if="$slots.default" />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { Offcanvas } from "bootstrap";

export default {
  props: {
    offcanvasTitle: String,
  },
  watch: {
    $route() {
      console.log("Collapse offcanvas (route change)"); //TODO - collapse only if collapse is open? :)
      var offcanvas = new Offcanvas(document.getElementById("offcanvas"));
      console.log(offcanvas);
      offcanvas.hide();
    },
  },
};
</script>

<style scoped>
#title {
  background: linear-gradient(92.73deg, #d62a26 16.73%, #8b2220 90.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>