import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'

import { aws4Interceptor } from 'aws4-axios'

import hasSession from '@/scripts/hasSession.ts'


import './../node_modules/bootstrap/dist/js/bootstrap.min.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt, faCaretRight, faCaretDown, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'



var app = createApp(App);


app.use(router).mount('#app');

//Fontawesome
library.add(faExternalLinkAlt, faCaretRight, faCaretDown, faExclamationCircle)
app.component('font-awesome-icon', FontAwesomeIcon)



app.directive('visible', function (el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
});






axios.defaults.baseURL = "https://api.kovarslaguna.com";


axios.interceptors.request.use(async function (request) {
    console.log(`Interceptor for ${request.method} ${request.url}`);

    //Redirect
    if (!await hasSession(publicEndpoint() ? { skipAutoRefresh: true } : undefined) && !publicEndpoint()) { //Only public endpoint
        //For !hasSession() conditional, skip auto refreshing IAM credentials if it's a public endpoint
        //  Otherwise, it'll call addSession which will call hasSession in the interceptor to make an infinite loop
        //No API requests should be made on /, so modifying it for the endpoint (like router/index.js) isn't necessary :)
        console.log(router.currentRoute.value.fullPath);
        router.push(`/password?redirect=${encodeURIComponent(router.currentRoute.value.fullPath)}`);
        return { //Request will still execute even on redirect, so cancel (goes to errorHandler)
            request,
            cancelToken: new axios.CancelToken(function (cancel) {
                cancel('Redirecting to /password (axios interceptor)');
            })
        }
    }

    console.log("No redirect necessary");

    //Sign request, but only if it's not a public endpoint
    if (publicEndpoint()) {
        console.log("Public endpoint, so skip signing request");
        return request;
    }

    var credentials = JSON.parse(localStorage["user-credentials"]);

    var interceptor = aws4Interceptor({
        options: {
            region: "us-west-1",
            service: "execute-api"
        },
        credentials: {
            accessKeyId: credentials.AccessKeyId,
            secretAccessKey: credentials.SecretKey,
            sessionToken: credentials.SessionToken
        }
    });
    return interceptor(request);


    function publicEndpoint() {
        return (request.method == "post" && request.url == "/app/session")
    }
});


app.config.globalProperties.$axios = axios;

export {
    app
}