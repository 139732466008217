import md from 'markdown-it';

var markdown = md("zero", { //Linkify needed to be specified in second parameter and in enable :)
    html: true,
    breaks: true,
    linkify : true
}).enable(["emphasis", "newline", "link", "autolink", "linkify"]);

export default {
    methods: {
        markdownContent(data) {
            return data ? markdown.render(data) : ""
        }
    }
};