import dayjs from 'dayjs'
import addSession from './addSession.ts'

export default async function (options = { skipAutoRefresh : false }) { //Checks for no or expired credentials
    // { skipAutoRefresh : true/false }
    //If IAM credentials need refresh, auto refreshses and returns true
    console.log("Running hasSession");

    if (options.skipAutoRefresh) {
        console.log("Skip auto refresh, if applicable");
    }

    if (localStorage["user-credentials"] == undefined) {
        console.log("No credentials (new user?)");
        return false;
    }

    var currentTime = dayjs();

    var appExpiration = dayjs(Number(localStorage["user-expires"]));

    if (currentTime.diff(appExpiration) > 0) { //App expired, so no session
        console.log("App expired (14 days)");
        return false;
    }
    

    var credentials = JSON.parse(localStorage["user-credentials"]);
    var IAMexpiration = dayjs(credentials.Expiration);

    if (currentTime.diff(IAMexpiration) > 0 && options.skipAutoRefresh != true) { //Positive is expired
        console.log("Expired IAM token, auto refresh and return true (for hasSession)");
        //If app isn't expired but IAM credentials are, refresh (without password) and return true
        await addSession(undefined);
    }

    console.log("Finished hasSession");
    return true;
}