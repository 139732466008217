<template>
  <div class="d-flex align-items-top">
    <div class="flex-shrink-1">
      <button id="expand" @click="expanded = !expanded">
        <font-awesome-icon
          :icon="['fas', (expanded ? 'caret-down' : 'caret-right')]" class="px-2 w-100"
        ></font-awesome-icon>
      </button>
    </div>
    <div class="flex-grow-1" style="min-width: 0;">
      <router-link
        :to="'/page/' + groupID + '/' + data.topicID"
        v-text="data.name"
        class="text-decoration-none text-dark hover-primary sidebar-item w-100 d-block"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
      ></router-link>
      <ul v-show="expanded" class="list-unstyled ps-3 mb-1">
        <li v-for="video in data.videos" :key="video.videoID">
          <router-link
            v-text="video.name"
            :to="'/page/' + groupID + '/' + data.topicID + '/' + video.videoID"
            class="text-decoration-none text-dark hover-primary sidebar-item w-100 d-block"
            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
          ></router-link>
        </li>
        <i v-if="data.videos.length == 0">No videos</i>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      groupID: this.$route.params.groupID,
      expanded: false,
    };
  },
  watch: {
    $route: {
      handler: function () {
        if (this.data.topicID == this.$route.params.topicID) {
          console.log(`Expand ${this.data.topicID} (on topic)`);
          this.expanded = true; //Expand if on topic
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/customVariables.scss";
#expand {
  background-color: transparent;
  border: none;
}

.hover-primary:hover {
  //@extend .text-primary;
  color : $primary !important;
}

.hover-underline:hover {
  //@extend .text-primary;
  text-decoration: underline;
}

.sidebar-item.router-link-active {
  //@extend .text-primary;
  //@extend .fw-bold;
  color : $primary !important;
  font-weight: 700 !important;
}
</style>