import CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import { app } from './../main.js'

var cognitoIdentity = new CognitoIdentity({
    region: "us-west-1"
});


export default async function (password) { //Changed to no vm instance required
    //app uses app.config.globalProperties.$axios, not app.$axios :)
    console.log("Running addSession, password = " + password);

    try {
        var data = (
            await app.config.globalProperties.$axios.post("/app/session", {
                password: password, //Call without argument if no password
                id : (localStorage["user-id"] != "undefined" ? localStorage["user-id"] : undefined)
            })
        ).data;

        if (data.id) {
            console.log("New id (in POST /app/session response), add to localStorage");
            localStorage["user-id"] = data.id;
        }

        if (data.expires) {
            console.log("New expires (in POST /app/session response), add to localStorage");
            localStorage["user-expires"] = data.expires;
        }
    } catch (err) {
        //err.response.data.message
        //err.response.status (number)
        throw err.response.data.message;
    }

    console.log("Getting IAM credentials from cognito tokens");

    var credentials = (
        await cognitoIdentity
            .getCredentialsForIdentity({
                IdentityId: data.cognito.IdentityId,
                Logins: {
                    "cognito-identity.amazonaws.com": data.cognito.Token,
                },
            })
            .promise()
    ).Credentials;
    localStorage["user-credentials"] = JSON.stringify(credentials);

    console.log("Set user (IAM) credentials in localStorage!");
}