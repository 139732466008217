<template>
  <div>
    <h1 :textContent="topic.name"></h1>
    <div class="pt-1" v-html="markdownContent(topic.content)"></div>
    <div v-if="list.length > 0 && loading == false">
      <!-- Render at the same time as others -->
      <hr class="my-3">
      <b>Videos in this topic:</b>
      <ul>
        <li v-for="video in list" :key="video.videoID">
          <router-link
          class="hover-underline text-decoration-none"
          :to="`/page/${$route.params.groupID}/${video.topicID}/${video.videoID}`"
          :textContent="video.name"
        ></router-link>
        </li>
      </ul>
    </div>
  </div>
  <loading v-if="loading" />
</template>

<script>
import markdown from "@/mixins/markdown.ts";
import loading from "@/components/loading.vue";

export default {
  data() {
    return {
      topic: {},
      loading: true,
    };
  },
  components: {
    loading,
  },
  props: {
    list: Array, //name, topicID, videoID
  },
  mixins: [markdown],

  emits : ["changeBreadcrumb"],

  async mounted() {
    //Change? To update every time the page changes?
    console.log("!");
    var params = this.$route.params;
    var data = (
      await this.$axios.get(
        `/groups/${params.groupID}/topics/${params.topicID}/`
      )
    ).data;
    console.log(this.topic);
    this.topic = data;
    this.loading = false;

    this.$emit('changeBreadcrumb', { type : "topic", value : data.name });
  },
};
</script>