<template>
  <div>
    <h1 :textContent="group.name"></h1>
    <div class="pt-1" v-text="group.description"></div>
    <div v-if="list.length > 0 && loading == false">
      <hr class="my-3">
      <!-- Render at the same time as others -->
      <b>Topics:</b>
      <ul>
        <li v-for="topic in list" :key="topic.topicID">
          <router-link
          class="hover-underline text-decoration-none"
          :to="`/page/${$route.params.groupID}/${topic.topicID}`"
          :textContent="topic.name"
        ></router-link>
        </li>
      </ul>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
import loading from "@/components/loading.vue";

export default {
  data() {
    return {
      group: {},
      loading: true,
    };
  },
  components: {
    loading,
  },
  props: {
    list: Array, //name, topicID
  },
  async mounted() {
    console.log("!");
    this.group = (
      await this.$axios.get(`/groups/${this.$route.params.groupID}/`)
    ).data;
    this.loading = false;
  },
};
</script>