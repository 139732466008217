<template>
  <div>
    <loading v-if="loading" />
    <h1 :textContent="video.name"></h1>
    <div class="mb-2" v-html="markdownContent(video.content)"></div>
    <div style="max-width: 1000px">
      <div class="ratio ratio-16x9">
        <iframe
          :src="this.video.URL ? ('https://player.vimeo.com/video/' + this.video.URL + '?badge=0&autopause=0&player_id=0') : ''"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import markdown from "@/mixins/markdown.ts";
import loading from '@/components/loading.vue'

export default {
  data() {
    return {
      video: {},
      loading : true
    };
  },
  components : {
    loading
  },
  mixins: [markdown],

  emits : ["changeBreadcrumb"],

  async mounted() {
    //Change? To update every time the page changes?
    console.log("!");
    var data = (
      await this.$axios.get(`/videos/${this.$route.params.videoID}/`)
    ).data;

    
    this.video = data;
    this.loading = false;

    this.$emit('changeBreadcrumb', { type : "video", value : data.name });
  },
};
</script>