<template>
  <div class="h-100 d-flex flex-column">
    <navbar>
      <sidebarItem
        v-for="topic in sidebar"
        :key="topic.topicID"
        :data="topic"
      />
    </navbar>

    <div class="container flex-grow-1 d-flex overflow-hidden" style="max-width: 900px">
      <div
        class="border-end pt-4 pe-4 overflow-auto d-none d-lg-block"
        style="width: 33%; max-width: 300px"
      >
        <router-link
          class="text-decoration-none text-dark h4 hover-underline w-100 d-block mb-3"
          style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
          :to="'/page/' + $route.params.groupID"
          v-text="groupName"
        ></router-link>
        <div>
          <div class="text-center" v-if="loading">...</div>
          <div v-if="!loading && sidebar.length == 0" class="text-center fst-italic">No videos yet, check back soon!</div>
          <sidebarItem
            v-for="topic in sidebar"
            :key="topic.topicID"
            :data="topic"
          />
        </div>
      </div>

      <div class="flex-grow-1 overflow-auto" style="flex-basis: 0">
        <div class="ps-lg-5 pt-4">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb flex-nowrap">
              <li class="breadcrumb-item text-truncate" v-show="$route.name != 'Group page'">
                <router-link
                  class="hover-underline text-decoration-none"
                  :to="'/page/' + $route.params.groupID"
                  :textContent="groupName"
                ></router-link>
              </li>
              <li class="breadcrumb-item text-truncate" v-show="$route.name != 'Group page'">
                <!-- Anything but group page -->
                <router-link
                  class="hover-underline text-decoration-none"
                  :to="
                    '/page/' +
                    $route.params.groupID +
                    '/' +
                    $route.params.topicID
                  "
                  :textContent="breadcrumb.topic"
                  v-show="$route.name == 'Video page'"
                ></router-link>
                <!-- Only show link on video page -->
                <span
                  :textContent="breadcrumb.topic"
                  v-show="$route.name == 'Topic page'"
                ></span>
              </li>
              <li class="breadcrumb-item text-truncate" v-show="$route.name == 'Video page'">
                <!-- Only video page, but no link -->
                <span :textContent="breadcrumb.video"></span>
              </li>
            </ol>
          </nav>
          <router-view
            :key="$route.fullPath"
            :list="list"
            @change-breadcrumb="changeBreadcrumb"
          ></router-view>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import navbar from "@/components/navbar.vue";
import sidebarItem from "@/components/page-sidebar-item.vue";

export default {
  components: {
    navbar,
    sidebarItem,
  },
  data() {
    return {
      sidebar: [],
      loading: true,
      groupName: "",
      breadcrumb: {
        topic: "N/A", //Group not necessary because groupName is already used
        video: "N/A",
      },
    };
  },
  computed: {
    list() {
      switch (this.$route.name) {
        case "Group page":
          return this.sidebar.map(function (topic) {
            //Return a list of topic names/ID's
            return { name: topic.name, topicID: topic.topicID };
          });

        case "Topic page": //TODO - can't read property of videos...so maybe something to do with sidebar not loaded?
          //It seems to render ok
          var vm = this;
          return this.sidebar.find(function (topic) {
            return topic.topicID == vm.$route.params.topicID;
          }).videos;
        default:
          return undefined;
      }
    },
  },
  async mounted() {
    var groupID = this.$route.params.groupID;
    this.groupName = (await this.$axios.get(`/groups/${groupID}/`)).data.name;
    this.sidebar = (await this.$axios.get(`/groups/${groupID}/toc`)).data;

    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://player.vimeo.com/api/player.js");
    plugin.async = true;
    document.head.appendChild(plugin);

    this.loading = false;
  },

  methods: {
    async changeBreadcrumb(data) {
      console.log(data);
      this.breadcrumb[data.type] = data.value;

      if (data.type != "video") {
        return;
      }

      console.log("Updating breadcrumb for video page's topic");
      console.log("topicID: " + this.$route.params.topicID);
      console.log(this.sidebar);

      if (this.sidebar.length > 0) {
        var vm = this;

        var topic = this.sidebar.find(function (item) {
          console.log(item);
          return item.topicID == vm.$route.params.topicID;
        });
        this.breadcrumb.topic = topic.name;
      } else {
        console.log("Sidebar not loaded, load topic separately");
        var groupID = this.$route.params.groupID;
        var topicID = this.$route.params.topicID;
        this.breadcrumb.topic = (
          await this.$axios.get(`/groups/${groupID}/topics/${topicID}/`)
        ).data.name;
      }
    },
  },
};
</script>

<style scoped>
#offcanvas {
  position: relative;
  height: 100%;
}

html,
body {
  height: 100%;
}

.hover-underline:hover {
  text-decoration: underline !important;
}
</style>