<template>
  <div id="container" class="h-100 text-white">
    <div class="text-center mx-auto" id="text">
      <h1 class="display-4 fw-bold mb-3">Laguna Video Library</h1>
      <p class="lead">
        Every move, helpful hint, and occasional crazy joke you'll need to succeed in Black Belt Prep!
      </p>

      <router-link class="btn btn-primary btn-lg px-5" to="/landing"
        >Get started</router-link
      >
    </div>
  </div>
</template>


<style lang="scss" scoped>
@import "./../scss/customVariables.scss";

#container {
  background-image: url("./../assets/home.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

#text {
  padding-top: 8rem;
  max-width: 600px;
  width: 90%;
}
</style>